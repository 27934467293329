import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#E8600B',
    },
    secondary: {
      main: '#21272C',
    },
  },
  //"PT Sans",helvetica,arial,sans-serif
  typography: {
    fontFamily: ['"PT Sans"', 'helvetica', 'arial', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#D5DCE6',
            color: '#fff',
          },
          '&': {
            border: '1px solid #CCCCCC',
            color: '#999999',
          },
        },
      },
    },
  },
});
