import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './shared/css/theme';
import { ToastProvider } from 'react-toast-notifications';
import { DialogAlert } from './modules/alert/DialogAlert';
/**
 * App component.
 *
 * @returns {React.FC} - App component.
 */
export const App: React.FC = () => {
  return (
    <ToastProvider placement="top-right" autoDismiss autoDismissTimeout={10000}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes />
          <DialogAlert />
        </ThemeProvider>
      </BrowserRouter>
    </ToastProvider>
  );
};
