import React from 'react';
import { Header } from './Header';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  }),
);

type LayoutProps = {
  children: React.ReactNode;
};
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <main className={classes.root}>{children}</main>
    </div>
  );
};
