import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/styles';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useSigUpMutationMutation } from '../../../shared/types/generated';
import { registerUserDataEvent } from '../auth-events';
import { useEvent } from '@cobuildlab/react-simple-state';

type AuthLoginFormProps = {
  onChangeEmail: () => void;
  onForgotPassword: () => void;
  onSuccess: (accessToken: string) => void;
};

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: '#E8600B!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFC7A4!important',
  },
  width: 200,
});

const StyledIcon = styled(EditIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
});

export const AuthLoginForm: React.FC<AuthLoginFormProps> = ({
  onChangeEmail,
  onForgotPassword,
  onSuccess,
}) => {
  const { email, password } = useEvent(registerUserDataEvent);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const [signUpMutation] = useSigUpMutationMutation({
    onCompleted: ({ SignUpMutation }) => {
      if (SignUpMutation?.access_token && onSuccess) {
        onSuccess(SignUpMutation?.access_token);
      }
      setIsLoading(false);
    },
    onError: () => {
      setError(!error);
      setIsLoading(false);
    },
  });

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  const onSubmit = (): void => {
    setIsLoading(true);
    signUpMutation({
      variables: {
        data: {
          email,
          password,
        },
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            <img
              src="/LogoVanguardiaMX.svg"
              alt="logo"
              width={225}
              height={25}
            />
            <StyledTypography fontSize={'18px'} variant="subtitle1">
              Inicia sesión
            </StyledTypography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label={'Email'}
            value={email}
            name="email"
            type="text"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: <StyledIcon onClick={() => onChangeEmail()} />,
            }}
            InputLabelProps={{
              style: { fontSize: '18px' },
            }}
            onChange={(event) => {
              registerUserDataEvent.dispatch({
                email: event.target.value as string,
                password,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={error}
            label={'Contraseña'}
            name="password"
            type={passwordType ?? 'password'}
            value={password}
            fullWidth
            variant="standard"
            helperText={error && 'Contraseña inválida, intente de nuevo'}
            FormHelperTextProps={{ style: { fontSize: '16px' } }}
            InputLabelProps={{
              style: { fontSize: '18px' },
            }}
            InputProps={{
              endAdornment: (
                <StyledVisibilityIcon onClick={() => handleVisibility()} />
              ),
              style: { fontSize: '18px' },
            }}
            onChange={(event) => {
              registerUserDataEvent.dispatch({
                email,
                password: event.target.value as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              isLoading={isLoading}
              variant="contained"
              size="large"
              color="secondary"
              disabled={!email}
              onClick={() => onSubmit()}
            >
              Continuar
            </StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StyledBox
            color="#E8600B"
            textAlign="center"
            fontSize={18}
            onClick={() => onForgotPassword()}
          >
            ¿Olvidó su clave?
          </StyledBox>
        </Grid>
      </Grid>
    </Box>
  );
};
