import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  AUTH_EMAIL,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_HOME,
  AUTH_RESET_PASSWORD,
  GOOGLE_CAPTCHA,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_NOT_FOUND,
  SITE_URL,
  DOMAIN_SITE,
  APP_ENV,
} from '../../shared/constans';
import { AuthLoginForm } from './components/AuthLoginForm';
import { AuthEmailForm } from './components/AuthEmailForm';
import { styled } from '@mui/styles';
import { AuthRegisterForm } from './components/AuthRegisterForm';
import { GoogleButton } from './components/GoogleButton';
import { FacebookButton } from './components/FacebookButton';
import { AppleButton } from './components/AppleButton';
import { RegisterButton } from './components/RegisterButton';
import {
  useCheckUserWithRecaptchaMutationMutation,
  useSignUpWithSocialProviderMutation,
  useSigUpMutationMutation,
} from '../../shared/types/generated';
import { AuthResetPasswordForm } from './components/AuthResetPasswordForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthForgotPassword } from './components/AuthForgotPassword';
import { AuthForgotPasswordNotFound } from './components/AuthForgotPasswordNotFound';
import { useCookies } from 'react-cookie';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { registerUserDataEvent } from './auth-events';
import { getCurrentUrl, getError } from './auth-utils';
import { useToasts } from 'react-toast-notifications';
import { createAnalyticEvent } from '../analytic-event/analytic-event-actions';

const StyledImage = styled('img')({});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
  color: '#E8600B',
});

export const Auth: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const { addToast } = useToasts();
  const [cookies] = useCookies(['analytics_uuid']);
  const currentUrl = getCurrentUrl();
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const isInStandaloneMode = query.has('isInStandaloneMode')
    ? (query.get('isInStandaloneMode') as string)
    : false;

  const { email } = useEvent(registerUserDataEvent);

  const [isLogin, setIsLogin] = useState<boolean>(
    query.has('action') && query.get('action') === 'login',
  );

  const [passwordResetToken, setPasswordResetToken] = useState(
    query.has('password_reset_token')
      ? (query.get('password_reset_token') as string)
      : '',
  );

  useEffect(() => {
    if (cookies.analytics_uuid) {
      callAnalyticEvent({
        name: 'wall_crashes_portal',
        user_id: 0,
        uuid: cookies.analytics_uuid,
      });
    }
  }, [callAnalyticEvent, cookies.analytics_uuid]);

  useEffect(() => {
    const error = getError();
    if (error) {
      return addToast(error);
    }
  }, [addToast]);

  const [step, setStep] = useState<string>(
    query.has('action') ? (query.get('action') as string) : AUTH_HOME,
  );
  const [isVerifiedUserLoading, setIsVerifiedUserLoading] =
    useState<boolean>(false);
  const [, setCookie] = useCookies(['_vuser']);

  const [signUpMutation] = useSigUpMutationMutation({
    onCompleted: ({ SignUpMutation }) => {
      if (SignUpMutation?.access_token) {
        setCookie('_vuser', SignUpMutation?.access_token, {
          path: '/',
          domain: DOMAIN_SITE,
        });
        window.location.href = 'https://vanguardia.com.mx';
      }
    },
    onError: () => {},
  });

  const [signUpWithSocialProviderMutation] =
    useSignUpWithSocialProviderMutation({
      onCompleted: ({ SignUpWithSocialProviderMutation }) => {
        if (SignUpWithSocialProviderMutation?.access_token) {
          setCookie('_vuser', SignUpWithSocialProviderMutation?.access_token, {
            path: '/',
            domain: DOMAIN_SITE,
          });
          window.location.href = 'https://vanguardia.com.mx';
        }
      },
      onError: (data) => {
        console.log('data', data);
      },
    });
  const [checkUserWithRecaptchaMutation] =
    useCheckUserWithRecaptchaMutationMutation({
      onCompleted: ({ CheckUserWithRecaptchaMutation }) => {
        if (
          CheckUserWithRecaptchaMutation?.success &&
          CheckUserWithRecaptchaMutation?.withPassword
        ) {
          setStep(AUTH_LOGIN);
        } else if (
          CheckUserWithRecaptchaMutation?.success &&
          !CheckUserWithRecaptchaMutation?.withPassword &&
          CheckUserWithRecaptchaMutation?.passwordResetToken
        ) {
          setPasswordResetToken(
            CheckUserWithRecaptchaMutation?.passwordResetToken,
          );
          setStep(AUTH_RESET_PASSWORD);
        } else {
          setStep(AUTH_REGISTER);
        }

        setIsVerifiedUserLoading(false);
      },
      onError: () => {
        setIsVerifiedUserLoading(false);
      },
    });

  let content: JSX.Element;

  switch (step) {
    case AUTH_LOGIN:
      content = (
        <AuthLoginForm
          onChangeEmail={() => setStep(AUTH_EMAIL)}
          onForgotPassword={() => setStep(AUTH_FORGOT_PASSWORD)}
          onSuccess={(accessToken) => {
            // TODO: enrutador al callback
            setCookie('_vuser', accessToken, {
              path: '/',
              domain: DOMAIN_SITE,
            });
            window.location.href = SITE_URL;
          }}
        />
      );
      break;
    case AUTH_REGISTER:
      content = (
        <AuthRegisterForm
          onSuccess={(data) => {
            setIsLogin(true);
            signUpMutation({
              variables: {
                data: {
                  email: data.email as string,
                  password: data.password as string,
                  uuid: cookies.analytics_uuid,
                  url: currentUrl ? currentUrl : window.location.href,
                },
              },
            });

            setIsVerifiedUserLoading(false);
          }}
          onBack={() => {
            setStep(AUTH_EMAIL);
            setIsVerifiedUserLoading(false);
          }}
        />
      );
      break;
    case AUTH_FORGOT_PASSWORD_NOT_FOUND:
      content = (
        <AuthForgotPasswordNotFound onBack={() => setStep(AUTH_EMAIL)} />
      );
      break;
    case AUTH_RESET_PASSWORD:
      content = (
        <AuthResetPasswordForm
          passwordResetToken={passwordResetToken}
          onSuccess={(data) => {
            signUpMutation({
              variables: {
                data: {
                  email: data.email as string,
                  password: data.password as string,
                  uuid: cookies.analytics_uuid,
                  url: currentUrl ? currentUrl : window.location.href,
                },
              },
            });
          }}
        />
      );
      break;
    case AUTH_FORGOT_PASSWORD:
      content = (
        <AuthForgotPassword
          onBack={() => {
            setStep(AUTH_LOGIN);
          }}
          onSuccess={() => {
            // setStep(AUTH_PASSWORD_EMAIL_SEND);
          }}
        />
      );
      break;
    case AUTH_EMAIL:
      content = (
        <AuthEmailForm
          isLogin={isLogin}
          isLoading={isVerifiedUserLoading}
          onBack={() => {
            setStep(AUTH_HOME);
            setIsVerifiedUserLoading(false);
          }}
          onSuccess={(token) => {
            checkUserWithRecaptchaMutation({
              variables: {
                data: {
                  token,
                  email,
                },
              },
            });
            setIsVerifiedUserLoading(true);
          }}
        />
      );
      break;
    default:
      content = (
        <>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <StyledTypography variant="subtitle1">
                    {!isLogin ? 'Regístrate gratis en' : 'Iniciar Sesión'}
                  </StyledTypography>
                  <div>
                    <StyledImage
                      src="/LogoVanguardiaMX.svg"
                      alt="logo"
                      width={225}
                      height={25}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={
                !navigator.userAgent.match(/FBAN|FBAV/i) && !isInStandaloneMode
                  ? 2
                  : 1
              }
            >
              {!navigator.userAgent.match(/FBAN|FBAV/i) &&
              !isInStandaloneMode ? (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <GoogleButton
                      onSuccess={(_email) =>
                        signUpWithSocialProviderMutation({
                          variables: {
                            data: {
                              email: _email,
                              provider: 'GOOGLE',
                              uuid: cookies.analytics_uuid,
                              url: currentUrl
                                ? currentUrl
                                : window.location.href,
                            },
                          },
                        })
                      }
                    />
                  </Box>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <FacebookButton
                    onSuccess={(_email) =>
                      signUpWithSocialProviderMutation({
                        variables: {
                          data: {
                            email: _email,
                            provider: 'FACEBOOK',
                            uuid: cookies.analytics_uuid,
                            url: currentUrl ? currentUrl : window.location.href,
                          },
                        },
                      })
                    }
                  />
                </Box>
              </Grid>

              {!navigator.userAgent.match(/FBAN|FBAV/i) &&
              APP_ENV !== 'local' &&
              !isInStandaloneMode ? (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    {
                      <AppleButton
                        onSuccess={(_email) =>
                          signUpWithSocialProviderMutation({
                            variables: {
                              data: {
                                email: _email,
                                provider: 'APPLE',
                                uuid: cookies.analytics_uuid,
                                url: currentUrl
                                  ? currentUrl
                                  : window.location.href,
                              },
                            },
                          })
                        }
                      />
                    }
                  </Box>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <RegisterButton onClick={() => setStep(AUTH_EMAIL)} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box fontSize={18} textAlign="center" mb={8}>
              {!isLogin ? '¿Ya tienes una cuenta?' : '¿No tienes cuenta aún?'}{' '}
              <StyledBox component="span" onClick={() => setIsLogin(!isLogin)}>
                {!isLogin ? 'Inicia sesión' : 'Crear una'}
              </StyledBox>
            </Box>
          </Grid>
          <Grid item xs={12} p={0}>
            <Box fontSize={14} display="flex" justifyContent="center">
              <Box
                style={{ color: '#E8600B', cursor: 'pointer' }}
                target="_blank"
                component="a"
                href="https://api.whatsapp.com/send?phone=528443808728"
                fontWeight="bold"
              >
                {'Ayuda'}
              </Box>
              <Box fontSize={12} component="span" mr={1} ml={1} color="#E8600B">
                {' | '}
              </Box>
              <Box
                style={{ color: '#E8600B', cursor: 'pointer' }}
                target="_blank"
                component="a"
                href="https://vanguardia.com.mx/aviso-de-privacidad"
                fontWeight="bold"
              >
                {'Privacidad'}
              </Box>
              <Box fontSize={12} component="span" color="#E8600B" mr={1} ml={1}>
                {' | '}
              </Box>
              <Box
                style={{ color: '#E8600B', cursor: 'pointer' }}
                target="_blank"
                component="a"
                href="https://vanguardia.com.mx/servicios/terminos-de-uso"
                fontWeight="bold"
              >
                {'Términos'}
              </Box>
            </Box>
          </Grid>
        </>
      );
      break;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA as string}>
      <Box height="100vh" alignItems="center" display="flex" px={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              {content}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GoogleReCaptchaProvider>
  );
};
