import React, { useCallback } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useEvent } from '@cobuildlab/react-simple-state';
import { registerUserDataEvent } from '../auth-events';

type AuthEmailFormProps = {
  onSuccess: (token: string) => void;
  isLoading: boolean;
  isLogin: boolean;
  onBack: () => void;
};

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: '#E8600B!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFC7A4!important',
  },
  width: 200,
});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
});

export const AuthEmailForm: React.FC<AuthEmailFormProps> = ({
  onSuccess,
  onBack,
  isLoading,
  isLogin,
}) => {
  const { email, password } = useEvent(registerUserDataEvent);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const checkUser = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('auth_email');
    onSuccess(token);
  }, [executeRecaptcha, onSuccess]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            <img
              src="/LogoVanguardiaMX.svg"
              alt="logo"
              width={225}
              height={25}
            />{' '}
            <StyledTypography variant="subtitle1">
              {!isLogin ? 'Regístrate con tu correo' : 'Inicia con tu correo'}
            </StyledTypography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            value={email}
            name="email"
            label="Email"
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              registerUserDataEvent.dispatch({
                email: event.target.value,
                password,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} mb={3}>
          <StyledBox
            color="#E8600B"
            textAlign="center"
            fontSize={18}
            onClick={() => onBack()}
          >
            {'< Volver a todas las opciones'}
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center">
            <StyledButton
              variant="contained"
              size="large"
              color="secondary"
              disabled={!(email && validator.isEmail(email as string))}
              onClick={() => checkUser()}
              isLoading={isLoading}
            >
              Continuar
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
