import { createAction } from '@cobuildlab/react-simple-state';
import {
  createAnalyticsErrorEvent,
  createAnalyticsEvent,
} from './analytic-event-events';
import axios from 'axios';
import { ANALYTICS_ENDPOINT } from '../../shared/constans';
import { getCurrentUrl } from '../auth/auth-utils';

export const createAnalyticEvent = createAction(
  createAnalyticsEvent,
  createAnalyticsErrorEvent,
  async (data: { user_id: number; uuid: string; name: string }) => {
    const response = await axios.post(
      `${ANALYTICS_ENDPOINT}/create`,
      {
        name: data.name,
        user_id: data.user_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'analytics-session': data.uuid,
          'analytics-session-origin': getCurrentUrl(),
        },
      },
    );

    return response.data;
  },
);
