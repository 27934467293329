import React, { useEffect } from 'react';
import { MainLoader } from '../../shared/components/MainLoader';
import { useCookies } from 'react-cookie';
import { DOMAIN_SITE } from '../../shared/constans';

export const AuthLogout: React.FC = () => {
  const [cookies, , removeCookie] = useCookies(['_vuser', 'loginWithSocial']);

  useEffect(() => {
    if (cookies._vuser || cookies.loginWithSocial) {
      removeCookie('_vuser', { path: '/', domain: DOMAIN_SITE });
      removeCookie('loginWithSocial', { path: '/', domain: DOMAIN_SITE });
    } else {
      window.location.href = 'https://vanguardia.com.mx';
    }
  }, [cookies._vuser, cookies.loginWithSocial, removeCookie]);

  return <MainLoader />;
};
