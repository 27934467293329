import React from 'react';
import { useStore } from '@cobuildlab/react-simple-state';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Theme,
  Button,
} from '@mui/material';
import { dialogAlertStore } from './alert-events';
import { closeDialogAlert } from './alert-actions';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    closeButton: {
      borderRadius: '99px!important',
      background: '#E8600B!important',
      color: 'white!important',
      width: '180px',
      justifyContent: 'center',
      margin: 'auto auto !important',
    },
    buttonDisconnect: {
      background: 'white!important',
      color: '#FA0000!important',
      border: '1px solid #FA0000',
      width: '180px',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    dialogContentText: {
      fontSize: 20,
    },
    positionCenter: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    defaultAlertBox: {
      width: '300px',
      margin: '0 auto',
      textAlign: 'center',
    },
  }),
);
export const DialogAlert: React.FC = () => {
  const { isOpen, message, extraAction } = useStore(dialogAlertStore);
  const classes = useStyles();

  return (
    <Dialog onClose={() => closeDialogAlert()} open={isOpen}>
      <DialogContent className={classes.defaultAlertBox}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.dialogContentText}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.positionCenter}>
        <Button
          className={classes.closeButton}
          onClick={() => {
            if (extraAction) extraAction();
            closeDialogAlert();
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
