import React from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { createAnalyticEvent } from '../../analytic-event/analytic-event-actions';
import { useCookies } from 'react-cookie';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '99px!important',
  height: 45,
  paddingRight: '40px!important',
});

const StyledImage = styled('img')({
  marginRight: 5,
  position: 'relative',
  right: 6,
});

type RegisterButtonType = {
  onClick: () => void;
};

export const RegisterButton: React.FC<RegisterButtonType> = ({ onClick }) => {
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const [cookies] = useCookies(['analytics_uuid']);
  return (
    <>
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => {
          onClick();
          callAnalyticEvent({
            name: 'auth_clicked',
            user_id: 0,
            uuid: cookies.analytics_uuid,
          });
        }}
        id="email-event-analytics"
      >
        <StyledImage src="/icon-email.svg" width={18} height={20} alt="logo" />{' '}
        Continuar con Email
      </StyledButton>
    </>
  );
};
