import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  MenuItem,
  Menu,
  Theme,
  Avatar,
  ListItemIcon,
  ListItemText,
  Hidden,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useAuth } from '../../../modules/auth/auth-hook';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    toolbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: 'black',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#000!important',

      margin: '0 0',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white!important',
      marginRight: '5px!important',
      marginLeft: '5px!important',
    },
    textRed: {
      color: '#FE0000',
    },
    groupButton: {
      borderBottom: '1px solid #999999!important',
      borderTop: '1px solid #CCCCCC!important',
      padding: '10px 0px 10px 0px',
      display: 'block!important',
    },
  }),
);

export const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Hidden mdDown={true} mdUp={false}>
            <Box
              position={'absolute'}
              top={'0'}
              left={'0'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              width={'100%'}
            >
              <button
                className={classes.cleanButton}
                onClick={() =>
                  (window.location.href = 'https://vanguardia.com.mx')
                }
              >
                <img
                  src="/LogoVanguardiaMX.svg"
                  alt="logo"
                  width={225}
                  height={25}
                />
              </button>
            </Box>
          </Hidden>
          <Hidden mdDown={false} mdUp={true}>
            <button
              className={classes.cleanButton}
              onClick={() =>
                (window.location.href = 'https://vanguardia.com.mx')
              }
            >
              <img
                src="/LogoVanguardiaMX.svg"
                alt="logo"
                width={225}
                height={25}
              />
            </button>
          </Hidden>
          <Box
            display={'flex'}
            justifyContent={'end'}
            width={'100%'}
            alignItems={'center'}
          >
            {/*{!subscription && (*/}
            {/*  <Button*/}
            {/*    className={classes.button}*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    disableElevation*/}
            {/*    style={{*/}
            {/*      textTransform: 'capitalize',*/}
            {/*      height: 45,*/}
            {/*      borderRadius: 99,*/}
            {/*      width: 150,*/}
            {/*      marginRight: 10,*/}
            {/*    }}*/}
            {/*    onClick={() => navigate('/suscripciones')}*/}
            {/*  >*/}
            {/*    <Typography>Suscribete</Typography>*/}
            {/*  </Button>*/}
            {/*)}*/}

            <div className={classes.menu}>
              <Hidden smDown={true} smUp={false}>
                <div>
                  {decodeURI(user?.name || '') || decodeURI(user?.email || '')}
                </div>
              </Hidden>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  src={user?.avatar as string}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <ListItemText>
                    Usuario:{' '}
                    <span className={classes.textRed}>
                      {decodeURI(user?.name as string)}
                    </span>{' '}
                  </ListItemText>
                </MenuItem>
                <div className={classes.groupButton}>
                  <MenuItem onClick={() => navigate('/')}> Mi perfil</MenuItem>
                  <MenuItem>Newsletter</MenuItem>
                  <MenuItem>
                    <a
                      style={{ textDecoration: 'none', color: 'black' }}
                      href="https://api.whatsapp.com/send/?phone=528443808728&text=Hola.+Me+estoy+contactando+desde+el+portal+de+https://vanguardia.com.mx/&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      Mensaje de whatsapp
                    </a>
                  </MenuItem>
                </div>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PowerSettingsNewSharpIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText onClick={() => navigate('/logout')}>
                    Cerrar sesión
                  </ListItemText>
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
