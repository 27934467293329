import React, { ReactNode, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from '../../shared/apollo';
import { useAuth } from '../auth/auth-hook';
import { MainLoader } from '../../shared/components/MainLoader';
import { useNavigate } from 'react-router-dom';

type SessionProps = {
  children?: ReactNode;
};
export const Session: React.FC<SessionProps> = ({ children }) => {
  const { isAuthenticated, isLoading, error } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return navigate('/autenticacion');
  }, [error, navigate]);

  if (isLoading) return <MainLoader />;
  if (!isAuthenticated) {
    navigate('auth', { state: { redirectTo: window.location } });
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
