import { useContext } from 'react';
import { DefaultValuesContextType, Context } from './AuthContext';

export const useAuth = (): DefaultValuesContextType => {
  const {
    isAuthenticated,
    isLoading,
    user,
    refetch,
    subscription,
    error,
    socialProvider,
    handleSocialProvider,
  } = useContext(Context);
  return {
    isAuthenticated,
    isLoading,
    user,
    refetch,
    error,
    socialProvider,
    handleSocialProvider,
    subscription,
  };
};
