/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

/** An example input */
export type ChangePasswordInput = {
  /** User email field */
  email?: InputMaybe<Scalars['String']>;
  /** User password field */
  password?: InputMaybe<Scalars['String']>;
  /** User password field */
  passwordResetToken?: InputMaybe<Scalars['String']>;
};

/** An example input */
export type CheckUserWithRecaptchaInput = {
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  /** A test field */
  token?: InputMaybe<Scalars['String']>;
};

/** A type */
export type CheckUserWithRecaptchaResponse = {
  __typename?: 'CheckUserWithRecaptchaResponse';
  /** Check if user have password */
  passwordResetToken?: Maybe<Scalars['String']>;
  /** Success */
  success?: Maybe<Scalars['Boolean']>;
  /** Check if user have password */
  withPassword?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type CreateSubscriptionInput = {
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  /** A test field */
  firstName?: InputMaybe<Scalars['String']>;
  /** A test field */
  lastName?: InputMaybe<Scalars['String']>;
  /** A test field */
  planCode?: InputMaybe<Scalars['String']>;
  /** A test field */
  token?: InputMaybe<Scalars['String']>;
};

/** A type */
export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  /** A test field */
  message?: Maybe<Scalars['String']>;
  /** The user name */
  subscription?: Maybe<Subscription>;
  /** A test field */
  success?: Maybe<Scalars['Boolean']>;
};

/** An example input */
export type CreateUserInput = {
  /** A test field */
  avatar?: InputMaybe<Scalars['String']>;
  /** A test field */
  email?: InputMaybe<Scalars['String']>;
  /** A test field */
  name?: InputMaybe<Scalars['String']>;
  /** A test field */
  password?: InputMaybe<Scalars['String']>;
  /** A test field */
  provider?: InputMaybe<CreateUserSocialProviderInput>;
  /** A test field */
  uuid?: InputMaybe<Scalars['String']>;
};

/** An example input */
export type CreateUserSocialProviderInput = {
  /** A test field */
  provider?: InputMaybe<Scalars['String']>;
  /** A test field */
  providerId?: InputMaybe<Scalars['String']>;
  /** A test field */
  urlRegister?: InputMaybe<Scalars['String']>;
  /** A test field */
  userId?: InputMaybe<Scalars['Int']>;
};

/** An example enum */
export enum InvoiceStatusEnum {
  /** test */
  Completed = 'COMPLETED',
  /** test */
  Failed = 'FAILED',
  /** test */
  Processing = 'PROCESSING',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** A mutation */
  CheckUserWithRecaptchaMutation?: Maybe<CheckUserWithRecaptchaResponse>;
  /** A mutation */
  SignUpMutation?: Maybe<SignUpResponse>;
  /** A mutation */
  SignUpWithSocialProviderMutation?: Maybe<SignUpResponse>;
  /** A mutation */
  UserForgotPasswordMutation?: Maybe<UserForgotPasswordResponse>;
  /** A mutation */
  changeUserPasswordMutation?: Maybe<User>;
  /** A mutation */
  createSubscription?: Maybe<CreateSubscriptionResponse>;
  createUser?: Maybe<User>;
  createUserSocialProvider?: Maybe<UserSocialProviderType>;
  /** A mutation */
  updateUser?: Maybe<User>;
};

export type MutationCheckUserWithRecaptchaMutationArgs = {
  data?: InputMaybe<CheckUserWithRecaptchaInput>;
};

export type MutationSignUpMutationArgs = {
  data?: InputMaybe<SignUpInput>;
};

export type MutationSignUpWithSocialProviderMutationArgs = {
  data?: InputMaybe<SignUpWithSocialProviderInput>;
};

export type MutationUserForgotPasswordMutationArgs = {
  data?: InputMaybe<UserForgotPasswordInput>;
};

export type MutationChangeUserPasswordMutationArgs = {
  data?: InputMaybe<ChangePasswordInput>;
};

export type MutationCreateSubscriptionArgs = {
  data?: InputMaybe<CreateSubscriptionInput>;
};

export type MutationCreateUserArgs = {
  data?: InputMaybe<CreateUserInput>;
};

export type MutationCreateUserSocialProviderArgs = {
  data?: InputMaybe<CreateUserSocialProviderInput>;
};

export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateUserInput>;
};

/** An example enum */
export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** A type */
export type Post = {
  __typename?: 'Post';
  /** A list of posts written by the user */
  author?: Maybe<UserPost>;
  /** Post content */
  content?: Maybe<Scalars['String']>;
  /** Post created */
  created_at?: Maybe<Scalars['String']>;
  /** User id */
  id?: Maybe<Scalars['Int']>;
  /** Post title */
  image?: Maybe<Scalars['String']>;
  /** Post title */
  title?: Maybe<Scalars['String']>;
};

export type PostPagination = {
  __typename?: 'PostPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Post>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
  /** The last page (number of pages) */
  last_page: Scalars['Int'];
  /** Number of items returned per page */
  per_page: Scalars['Int'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  post?: Maybe<Post>;
  posts?: Maybe<PostPagination>;
  user?: Maybe<User>;
  /** A query */
  userByEmail?: Maybe<User>;
};

export type QueryPostArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<OrderEnum>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryUserByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  isSocialProvider?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** An example input */
export type SignUpInput = {
  /** A user email field */
  email?: InputMaybe<Scalars['String']>;
  /** A user password field */
  password?: InputMaybe<Scalars['String']>;
  /** A user email field */
  url?: InputMaybe<Scalars['String']>;
  /** A user email field */
  uuid?: InputMaybe<Scalars['String']>;
};

/** A type */
export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  /** Token */
  access_token?: Maybe<Scalars['String']>;
};

/** An example input */
export type SignUpWithSocialProviderInput = {
  /** A user email field */
  email?: InputMaybe<Scalars['String']>;
  /** A user email field */
  provider?: InputMaybe<Scalars['String']>;
  /** A user email field */
  url?: InputMaybe<Scalars['String']>;
  /** A user email field */
  uuid?: InputMaybe<Scalars['String']>;
};

/** A type */
export type Subscription = {
  __typename?: 'Subscription';
  /** A test field */
  description?: Maybe<Scalars['String']>;
  /** A test field */
  endDate?: Maybe<Scalars['String']>;
  /** A test field */
  id?: Maybe<Scalars['Int']>;
  /** A test field */
  name?: Maybe<Scalars['String']>;
  /** A test field */
  startDate?: Maybe<Scalars['String']>;
  /** A test field */
  status?: Maybe<Scalars['String']>;
  /** A test field */
  subscriptionId?: Maybe<Scalars['String']>;
  /** A test field */
  userId?: Maybe<Scalars['Int']>;
};

/** An example input */
export type UpdateUserInput = {
  /** The user name */
  avatar?: InputMaybe<Scalars['String']>;
  /** The user name */
  birthday?: InputMaybe<Scalars['String']>;
  /** The user email */
  email?: InputMaybe<Scalars['String']>;
  /** Education enum */
  file?: InputMaybe<Scalars['Upload']>;
  /** User id */
  id: Scalars['Int'];
  /** The user name */
  name?: InputMaybe<Scalars['String']>;
  /** The user password */
  password?: InputMaybe<Scalars['String']>;
  /** Education enum */
  sex?: InputMaybe<UserSexEnum>;
};

/** A type */
export type User = {
  __typename?: 'User';
  /** The user name */
  avatar?: Maybe<Scalars['String']>;
  /** The user name */
  birthday?: Maybe<Scalars['String']>;
  /** The user email */
  email: Scalars['String'];
  /** User id */
  id: Scalars['Int'];
  /** The user name */
  name: Scalars['String'];
  /** The user password */
  password: Scalars['String'];
  /** The user name */
  provider?: Maybe<Array<Maybe<UserSocialProviderType>>>;
  /** Education enum */
  sex?: Maybe<UserSexEnum>;
  /** The user name */
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
};

/** An example input */
export type UserForgotPasswordInput = {
  /** A user email field */
  email?: InputMaybe<Scalars['String']>;
  /** A redirect article */
  form?: InputMaybe<Scalars['String']>;
  /** A redirect article */
  redirectUrl?: InputMaybe<Scalars['String']>;
};

/** A type */
export type UserForgotPasswordResponse = {
  __typename?: 'UserForgotPasswordResponse';
  /** Response */
  success?: Maybe<Scalars['Boolean']>;
};

/** A type */
export type UserPost = {
  __typename?: 'UserPost';
  /** Post id */
  id?: Maybe<Scalars['Int']>;
  /** Post id */
  post_id?: Maybe<Scalars['Int']>;
  /** User model */
  user?: Maybe<User>;
  /** User id */
  user_id?: Maybe<Scalars['Int']>;
};

/** An example enum */
export enum UserSexEnum {
  /** test */
  Female = 'FEMALE',
  /** test */
  Male = 'MALE',
  /** test */
  NoApply = 'NO_APPLY',
}

/** An example enum */
export enum UserSocialProviderEnum {
  /** APPLE */
  Apple = 'APPLE',
  /** FACEBOOK */
  Facebook = 'FACEBOOK',
  /** GOOGLE */
  Google = 'GOOGLE',
  /** SYSTEM */
  System = 'SYSTEM',
}

/** A type */
export type UserSocialProviderType = {
  __typename?: 'UserSocialProviderType';
  /** Post id */
  id?: Maybe<Scalars['Int']>;
  provider?: Maybe<UserSocialProviderEnum>;
  /** A test field */
  providerId?: Maybe<Scalars['String']>;
  /** A test field */
  urlRegister?: Maybe<Scalars['String']>;
  /** User id */
  user_id?: Maybe<Scalars['Int']>;
};

export type CreateSubscriptionMutationVariables = Exact<{
  data: CreateSubscriptionInput;
}>;

export type CreateSubscriptionMutation = {
  __typename?: 'Mutation';
  createSubscription: {
    __typename?: 'CreateSubscriptionResponse';
    success: boolean | null;
    message: string | null;
    subscription: { __typename?: 'Subscription'; id: number | null } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  data?: InputMaybe<CreateUserInput>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: number;
    email: string;
    name: string;
    avatar: string | null;
  } | null;
};

export type CreateUserProviderMutationVariables = Exact<{
  data?: InputMaybe<CreateUserSocialProviderInput>;
}>;

export type CreateUserProviderMutation = {
  __typename?: 'Mutation';
  createUserSocialProvider: {
    __typename?: 'UserSocialProviderType';
    id: number | null;
    user_id: number | null;
    provider: UserSocialProviderEnum | null;
  } | null;
};

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
  provider?: InputMaybe<Scalars['String']>;
  isSocialProvider?: InputMaybe<Scalars['Boolean']>;
}>;

export type UserByEmailQuery = {
  __typename?: 'Query';
  userByEmail: {
    __typename?: 'User';
    id: number;
    email: string;
    name: string;
    avatar: string | null;
    password: string;
    provider: Array<{
      __typename?: 'UserSocialProviderType';
      id: number | null;
      provider: UserSocialProviderEnum | null;
      providerId: string | null;
    } | null> | null;
  } | null;
};

export type SigUpMutationMutationVariables = Exact<{
  data?: InputMaybe<SignUpInput>;
}>;

export type SigUpMutationMutation = {
  __typename?: 'Mutation';
  SignUpMutation: {
    __typename?: 'SignUpResponse';
    access_token: string | null;
  } | null;
};

export type ChangeUserPasswordMutationVariables = Exact<{
  data?: InputMaybe<ChangePasswordInput>;
}>;

export type ChangeUserPasswordMutation = {
  __typename?: 'Mutation';
  changeUserPasswordMutation: {
    __typename?: 'User';
    id: number;
    email: string;
  } | null;
};

export type SignUpWithSocialProviderMutationVariables = Exact<{
  data?: InputMaybe<SignUpWithSocialProviderInput>;
}>;

export type SignUpWithSocialProviderMutation = {
  __typename?: 'Mutation';
  SignUpWithSocialProviderMutation: {
    __typename?: 'SignUpResponse';
    access_token: string | null;
  } | null;
};

export type CheckUserWithRecaptchaMutationMutationVariables = Exact<{
  data?: InputMaybe<CheckUserWithRecaptchaInput>;
}>;

export type CheckUserWithRecaptchaMutationMutation = {
  __typename?: 'Mutation';
  CheckUserWithRecaptchaMutation: {
    __typename?: 'CheckUserWithRecaptchaResponse';
    success: boolean | null;
    withPassword: boolean | null;
    passwordResetToken: string | null;
  } | null;
};

export type FetchUserQueryVariables = Exact<{ [key: string]: never }>;

export type FetchUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    sex: UserSexEnum | null;
    birthday: string | null;
    subscriptions: Array<{
      __typename?: 'Subscription';
      id: number | null;
      name: string | null;
      description: string | null;
      startDate: string | null;
      endDate: string | null;
      status: string | null;
      subscriptionId: string | null;
    } | null> | null;
    provider: Array<{
      __typename?: 'UserSocialProviderType';
      id: number | null;
      provider: UserSocialProviderEnum | null;
      providerId: string | null;
    } | null> | null;
  } | null;
};

export type UserForgotPasswordMutationMutationVariables = Exact<{
  data?: InputMaybe<UserForgotPasswordInput>;
}>;

export type UserForgotPasswordMutationMutation = {
  __typename?: 'Mutation';
  UserForgotPasswordMutation: {
    __typename?: 'UserForgotPasswordResponse';
    success: boolean | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  data?: InputMaybe<UpdateUserInput>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: number;
    name: string;
    email: string;
    avatar: string | null;
    sex: UserSexEnum | null;
    birthday: string | null;
  } | null;
};

export const CreateSubscriptionDocument = gql`
  mutation createSubscription($data: CreateSubscriptionInput!) {
    createSubscription(data: $data) {
      success
      subscription {
        id
      }
      message
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CreateSubscriptionDocument, options);
}
export type CreateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateSubscriptionMutation
>;
export type CreateSubscriptionMutationResult =
  Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($data: CreateUserInput) {
    createUser(data: $data) {
      id
      email
      name
      avatar
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const CreateUserProviderDocument = gql`
  mutation CreateUserProvider($data: CreateUserSocialProviderInput) {
    createUserSocialProvider(data: $data) {
      id
      user_id
      provider
    }
  }
`;
export type CreateUserProviderMutationFn = Apollo.MutationFunction<
  CreateUserProviderMutation,
  CreateUserProviderMutationVariables
>;

/**
 * __useCreateUserProviderMutation__
 *
 * To run a mutation, you first call `useCreateUserProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProviderMutation, { data, loading, error }] = useCreateUserProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserProviderMutation,
    CreateUserProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserProviderMutation,
    CreateUserProviderMutationVariables
  >(CreateUserProviderDocument, options);
}
export type CreateUserProviderMutationHookResult = ReturnType<
  typeof useCreateUserProviderMutation
>;
export type CreateUserProviderMutationResult =
  Apollo.MutationResult<CreateUserProviderMutation>;
export type CreateUserProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateUserProviderMutation,
  CreateUserProviderMutationVariables
>;
export const UserByEmailDocument = gql`
  query userByEmail(
    $email: String!
    $provider: String
    $isSocialProvider: Boolean
  ) {
    userByEmail(
      email: $email
      provider: $provider
      isSocialProvider: $isSocialProvider
    ) {
      id
      email
      name
      avatar
      password
      provider {
        id
        provider
        providerId
      }
    }
  }
`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      provider: // value for 'provider'
 *      isSocialProvider: // value for 'isSocialProvider'
 *   },
 * });
 */
export function useUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserByEmailQuery,
    UserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export function useUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByEmailQuery,
    UserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<
  typeof useUserByEmailLazyQuery
>;
export type UserByEmailQueryResult = Apollo.QueryResult<
  UserByEmailQuery,
  UserByEmailQueryVariables
>;
export const SigUpMutationDocument = gql`
  mutation SigUpMutation($data: SignUpInput) {
    SignUpMutation(data: $data) {
      access_token
    }
  }
`;
export type SigUpMutationMutationFn = Apollo.MutationFunction<
  SigUpMutationMutation,
  SigUpMutationMutationVariables
>;

/**
 * __useSigUpMutationMutation__
 *
 * To run a mutation, you first call `useSigUpMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigUpMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sigUpMutationMutation, { data, loading, error }] = useSigUpMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSigUpMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigUpMutationMutation,
    SigUpMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SigUpMutationMutation,
    SigUpMutationMutationVariables
  >(SigUpMutationDocument, options);
}
export type SigUpMutationMutationHookResult = ReturnType<
  typeof useSigUpMutationMutation
>;
export type SigUpMutationMutationResult =
  Apollo.MutationResult<SigUpMutationMutation>;
export type SigUpMutationMutationOptions = Apollo.BaseMutationOptions<
  SigUpMutationMutation,
  SigUpMutationMutationVariables
>;
export const ChangeUserPasswordDocument = gql`
  mutation ChangeUserPassword($data: ChangePasswordInput) {
    changeUserPasswordMutation(data: $data) {
      id
      email
    }
  }
`;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >(ChangeUserPasswordDocument, options);
}
export type ChangeUserPasswordMutationHookResult = ReturnType<
  typeof useChangeUserPasswordMutation
>;
export type ChangeUserPasswordMutationResult =
  Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;
export const SignUpWithSocialProviderDocument = gql`
  mutation SignUpWithSocialProvider($data: SignUpWithSocialProviderInput) {
    SignUpWithSocialProviderMutation(data: $data) {
      access_token
    }
  }
`;
export type SignUpWithSocialProviderMutationFn = Apollo.MutationFunction<
  SignUpWithSocialProviderMutation,
  SignUpWithSocialProviderMutationVariables
>;

/**
 * __useSignUpWithSocialProviderMutation__
 *
 * To run a mutation, you first call `useSignUpWithSocialProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithSocialProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithSocialProviderMutation, { data, loading, error }] = useSignUpWithSocialProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpWithSocialProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpWithSocialProviderMutation,
    SignUpWithSocialProviderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignUpWithSocialProviderMutation,
    SignUpWithSocialProviderMutationVariables
  >(SignUpWithSocialProviderDocument, options);
}
export type SignUpWithSocialProviderMutationHookResult = ReturnType<
  typeof useSignUpWithSocialProviderMutation
>;
export type SignUpWithSocialProviderMutationResult =
  Apollo.MutationResult<SignUpWithSocialProviderMutation>;
export type SignUpWithSocialProviderMutationOptions =
  Apollo.BaseMutationOptions<
    SignUpWithSocialProviderMutation,
    SignUpWithSocialProviderMutationVariables
  >;
export const CheckUserWithRecaptchaMutationDocument = gql`
  mutation CheckUserWithRecaptchaMutation($data: CheckUserWithRecaptchaInput) {
    CheckUserWithRecaptchaMutation(data: $data) {
      success
      withPassword
      passwordResetToken
    }
  }
`;
export type CheckUserWithRecaptchaMutationMutationFn = Apollo.MutationFunction<
  CheckUserWithRecaptchaMutationMutation,
  CheckUserWithRecaptchaMutationMutationVariables
>;

/**
 * __useCheckUserWithRecaptchaMutationMutation__
 *
 * To run a mutation, you first call `useCheckUserWithRecaptchaMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserWithRecaptchaMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserWithRecaptchaMutationMutation, { data, loading, error }] = useCheckUserWithRecaptchaMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckUserWithRecaptchaMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckUserWithRecaptchaMutationMutation,
    CheckUserWithRecaptchaMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckUserWithRecaptchaMutationMutation,
    CheckUserWithRecaptchaMutationMutationVariables
  >(CheckUserWithRecaptchaMutationDocument, options);
}
export type CheckUserWithRecaptchaMutationMutationHookResult = ReturnType<
  typeof useCheckUserWithRecaptchaMutationMutation
>;
export type CheckUserWithRecaptchaMutationMutationResult =
  Apollo.MutationResult<CheckUserWithRecaptchaMutationMutation>;
export type CheckUserWithRecaptchaMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CheckUserWithRecaptchaMutationMutation,
    CheckUserWithRecaptchaMutationMutationVariables
  >;
export const FetchUserDocument = gql`
  query fetchUser {
    user {
      id
      name
      email
      avatar
      sex
      birthday
      subscriptions {
        id
        name
        description
        startDate
        endDate
        status
        subscriptionId
      }
      provider {
        id
        provider
        providerId
      }
    }
  }
`;

/**
 * __useFetchUserQuery__
 *
 * To run a query within a React component, call `useFetchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchUserQuery,
    FetchUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchUserQuery, FetchUserQueryVariables>(
    FetchUserDocument,
    options,
  );
}
export function useFetchUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserQuery,
    FetchUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchUserQuery, FetchUserQueryVariables>(
    FetchUserDocument,
    options,
  );
}
export type FetchUserQueryHookResult = ReturnType<typeof useFetchUserQuery>;
export type FetchUserLazyQueryHookResult = ReturnType<
  typeof useFetchUserLazyQuery
>;
export type FetchUserQueryResult = Apollo.QueryResult<
  FetchUserQuery,
  FetchUserQueryVariables
>;
export const UserForgotPasswordMutationDocument = gql`
  mutation userForgotPasswordMutation($data: UserForgotPasswordInput) {
    UserForgotPasswordMutation(data: $data) {
      success
    }
  }
`;
export type UserForgotPasswordMutationMutationFn = Apollo.MutationFunction<
  UserForgotPasswordMutationMutation,
  UserForgotPasswordMutationMutationVariables
>;

/**
 * __useUserForgotPasswordMutationMutation__
 *
 * To run a mutation, you first call `useUserForgotPasswordMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserForgotPasswordMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userForgotPasswordMutationMutation, { data, loading, error }] = useUserForgotPasswordMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserForgotPasswordMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserForgotPasswordMutationMutation,
    UserForgotPasswordMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserForgotPasswordMutationMutation,
    UserForgotPasswordMutationMutationVariables
  >(UserForgotPasswordMutationDocument, options);
}
export type UserForgotPasswordMutationMutationHookResult = ReturnType<
  typeof useUserForgotPasswordMutationMutation
>;
export type UserForgotPasswordMutationMutationResult =
  Apollo.MutationResult<UserForgotPasswordMutationMutation>;
export type UserForgotPasswordMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UserForgotPasswordMutationMutation,
    UserForgotPasswordMutationMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($data: UpdateUserInput) {
    updateUser(data: $data) {
      id
      name
      email
      avatar
      sex
      birthday
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
