import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import {
  AUTH_EMAIL,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_HOME,
  AUTH_RESET_PASSWORD,
  GOOGLE_CAPTCHA,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_NOT_FOUND,
  TARGET_ORIGIN_URL,
  APP_ENV,
} from '../../../shared/constans';
import { AuthEmailForm } from '../components/AuthEmailForm';
import { styled } from '@mui/styles';
import { RegisterButton } from '../components/RegisterButton';
import {
  useCheckUserWithRecaptchaMutationMutation,
  useSignUpWithSocialProviderMutation,
  useSigUpMutationMutation,
} from '../../../shared/types/generated';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthForgotPasswordNotFound } from '../components/AuthForgotPasswordNotFound';
import { isMobile, isAndroid } from 'react-device-detect';
import { ExternalAuthLoginForm } from './components/ExternalAuthLoginForm';
import { ExternalAuthRegisterForm } from './components/ExternalAuthRegisterForm';
import { ExternalAuthResetPasswordForm } from './components/ExternalAuthResetPasswordForm';
import { ExternalAuthForgotPassword } from './components/ExternalAuthForgotPassword';
import { registerUserDataEvent } from '../auth-events';
import { useEvent } from '@cobuildlab/react-simple-state';
import { getCurrentUrl, getError } from '../auth-utils';
import { ExternalGoogleButton } from './components/ExternalGoogleButton';
import { ExternalFacebookButton } from './components/ExternalFacebookButton';
import { ExternalAppleButton } from './components/ExternalAppleButton';
import { useCookies } from 'react-cookie';

const StyledImage = styled('img')({});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
  color: '#E8600B',
});

export const ExternalAuth: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const error = getError();
  const { email } = useEvent(registerUserDataEvent);
  const [cookies] = useCookies(['analytics_uuid']);
  const currentUrl = getCurrentUrl();

  const [isLogin, setIsLogin] = useState<boolean>(
    query.has('action') && query.get('action') === 'login',
  );

  const [passwordResetToken, setPasswordResetToken] = useState(
    query.has('password_reset_token')
      ? (query.get('password_reset_token') as string)
      : '',
  );

  const [step, setStep] = useState<string>(
    query.has('action') ? (query.get('action') as string) : AUTH_HOME,
  );
  const [isVerifiedUserLoading, setIsVerifiedUserLoading] =
    useState<boolean>(false);

  const [signUpMutation] = useSigUpMutationMutation({
    onCompleted: ({ SignUpMutation }) => {
      if (SignUpMutation?.access_token) {
        window.parent.postMessage(
          {
            access_token: SignUpMutation?.access_token,
          },
          TARGET_ORIGIN_URL,
        );
      }
    },
    onError: () => {},
  });

  const [signUpWithSocialProviderMutation] =
    useSignUpWithSocialProviderMutation({
      onCompleted: ({ SignUpWithSocialProviderMutation }) => {
        if (SignUpWithSocialProviderMutation?.access_token) {
          window.parent.postMessage(
            {
              access_token: SignUpWithSocialProviderMutation?.access_token,
            },
            TARGET_ORIGIN_URL,
          );
        }
      },
    });

  const [checkUserWithRecaptchaMutation] =
    useCheckUserWithRecaptchaMutationMutation({
      onCompleted: ({ CheckUserWithRecaptchaMutation }) => {
        if (
          CheckUserWithRecaptchaMutation?.success &&
          CheckUserWithRecaptchaMutation?.withPassword
        ) {
          setStep(AUTH_LOGIN);
        } else if (
          CheckUserWithRecaptchaMutation?.success &&
          !CheckUserWithRecaptchaMutation?.withPassword &&
          CheckUserWithRecaptchaMutation?.passwordResetToken
        ) {
          setPasswordResetToken(
            CheckUserWithRecaptchaMutation?.passwordResetToken,
          );
          setStep(AUTH_RESET_PASSWORD);
        } else {
          setStep(AUTH_REGISTER);
        }

        setIsVerifiedUserLoading(false);
      },
      onError: () => {
        setIsVerifiedUserLoading(false);
      },
    });

  let content = <></>;
  const isWebView =
    navigator.userAgent.toLowerCase().includes('wv') ||
    navigator.userAgent.toLowerCase().includes('magpoc');

  switch (step) {
    case AUTH_LOGIN:
      content = (
        <ExternalAuthLoginForm
          onChangeEmail={() => setStep(AUTH_EMAIL)}
          onForgotPassword={() => setStep(AUTH_FORGOT_PASSWORD)}
          onSuccess={(accessToken) => {
            parent.postMessage(
              {
                access_token: accessToken,
              },
              TARGET_ORIGIN_URL,
            );
          }}
          onBack={() => {
            setStep(AUTH_HOME);
            setIsVerifiedUserLoading(false);
          }}
        />
      );
      break;
    case AUTH_REGISTER:
      content = (
        <ExternalAuthRegisterForm
          onSuccess={(data) => {
            setIsLogin(true);
            signUpMutation({
              variables: {
                data: {
                  email: data.email as string,
                  password: data.password as string,
                  uuid: cookies.analytics_uuid,
                  url: (currentUrl ? currentUrl : window.location.href).replace(
                    /\/+$/,
                    '',
                  ),
                },
              },
            });

            setIsVerifiedUserLoading(false);
          }}
          onBack={() => {
            setStep(AUTH_EMAIL);
            setIsVerifiedUserLoading(false);
          }}
          onBackHome={() => {
            setStep(AUTH_HOME);
            setIsVerifiedUserLoading(false);
          }}
        />
      );
      break;
    case AUTH_FORGOT_PASSWORD_NOT_FOUND:
      content = (
        <AuthForgotPasswordNotFound onBack={() => setStep(AUTH_EMAIL)} />
      );
      break;
    case AUTH_RESET_PASSWORD:
      content = (
        <ExternalAuthResetPasswordForm
          passwordResetToken={passwordResetToken}
          onSuccess={(data) => {
            signUpMutation({
              variables: {
                data: {
                  email: data.email as string,
                  password: data.password as string,
                  uuid: cookies.analytics_uuid,
                  url: currentUrl ? currentUrl : window.location.href,
                },
              },
            });
          }}
        />
      );
      break;
    case AUTH_FORGOT_PASSWORD:
      content = (
        <ExternalAuthForgotPassword
          onBack={() => {
            setStep(AUTH_LOGIN);
          }}
        />
      );
      break;
    case AUTH_EMAIL:
      content = (
        <AuthEmailForm
          isLogin={isLogin}
          isLoading={isVerifiedUserLoading}
          onBack={() => {
            setStep(AUTH_HOME);
            setIsVerifiedUserLoading(false);
          }}
          onSuccess={(token) => {
            checkUserWithRecaptchaMutation({
              variables: {
                data: {
                  token,
                  email: String(email),
                },
              },
            });
            setIsVerifiedUserLoading(true);
          }}
        />
      );
      break;
    default:
      content = (
        <>
          <Grid item xs={12}>
            <Box mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <StyledTypography variant="subtitle1">
                    {!isLogin ? 'Regístrate gratis en' : 'Iniciar Sesión'}
                  </StyledTypography>
                  <div>
                    <StyledImage
                      src="/LogoVanguardiaMX.svg"
                      alt="logo"
                      width={225}
                      height={25}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={
                !navigator.userAgent.match(/FBAN|FBAV/i) && !isWebView ? 2 : 1
              }
            >
              {!navigator.userAgent.match(/FBAN|FBAV/i) && !isWebView ? (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <ExternalGoogleButton
                      onSuccess={(_email) =>
                        signUpWithSocialProviderMutation({
                          variables: {
                            data: {
                              email: _email,
                              provider: 'GOOGLE',
                              uuid: cookies.analytics_uuid,
                              url: (currentUrl
                                ? currentUrl
                                : window.location.href
                              ).replace(/\/+$/, ''),
                            },
                          },
                        })
                      }
                    />
                  </Box>
                </Grid>
              ) : null}
              {!(isWebView && isAndroid) && (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <ExternalFacebookButton
                      onSuccess={(_email) =>
                        signUpWithSocialProviderMutation({
                          variables: {
                            data: {
                              email: _email,
                              provider: 'FACEBOOK',
                              uuid: cookies.analytics_uuid,
                              url: (currentUrl
                                ? currentUrl
                                : window.location.href
                              ).replace(/\/+$/, ''),
                            },
                          },
                        })
                      }
                    />
                  </Box>
                </Grid>
              )}
              {!navigator.userAgent.match(/FBAN|FBAV/i) &&
              APP_ENV !== 'local' &&
              !isWebView ? (
                <Grid item xs={12}>
                  <Box textAlign="center">
                    {
                      <ExternalAppleButton
                        onSuccess={(_email) =>
                          signUpWithSocialProviderMutation({
                            variables: {
                              data: {
                                email: _email,
                                provider: 'APPLE',
                                uuid: cookies.analytics_uuid,
                                url: (currentUrl
                                  ? currentUrl
                                  : window.location.href
                                ).replace(/\/+$/, ''),
                              },
                            },
                          })
                        }
                      />
                    }
                  </Box>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <RegisterButton onClick={() => setStep(AUTH_EMAIL)} />
                </Box>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography style={{ color: 'red', textAlign: 'justify' }}>
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box fontSize={18} textAlign="center" mb={8}>
              <Box component={isWebView || isMobile ? 'div' : 'span'}>
                {!isLogin ? '¿Ya tienes una cuenta?' : '¿No tienes cuenta aún?'}{' '}
              </Box>
              <StyledBox
                component={isWebView || isMobile ? 'div' : 'span'}
                onClick={() => setIsLogin(!isLogin)}
              >
                {!isLogin ? 'Inicia sesión' : 'Crear una'}
              </StyledBox>
            </Box>
          </Grid>
          <Grid item xs={12} p={0}>
            <Box fontSize={14} display="flex" justifyContent="center">
              <Box
                style={{ color: '#E8600B', cursor: 'pointer' }}
                target="_blank"
                component="a"
                href="https://api.whatsapp.com/send?phone=528443808728"
                fontWeight="bold"
              >
                {'Ayuda'}
              </Box>
              <Box fontSize={12} component="span" mr={1} ml={1} color="#E8600B">
                {' | '}
              </Box>
              <Box
                style={{ color: '#E8600B', cursor: 'pointer' }}
                target="_blank"
                component="a"
                href="https://vanguardia.com.mx/aviso-de-privacidad"
                fontWeight="bold"
              >
                {'Privacidad'}
              </Box>
              <Box fontSize={12} component="span" color="#E8600B" mr={1} ml={1}>
                {' | '}
              </Box>
              <Box
                style={{ color: '#E8600B', cursor: 'pointer' }}
                target="_blank"
                component="a"
                href="https://vanguardia.com.mx/servicios/terminos-de-uso"
                fontWeight="bold"
              >
                {'Términos'}
              </Box>
            </Box>
          </Grid>
        </>
      );
      break;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA as string}>
      <Box height="100vh" alignItems="center" display="flex" px={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              {content}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GoogleReCaptchaProvider>
  );
};
