import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { useUserForgotPasswordMutationMutation } from '../../../../shared/types/generated';
import { useEvent } from '@cobuildlab/react-simple-state';
import { registerUserDataEvent } from '../../auth-events';

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledEmailTypography = styled(Typography)({
  fontSize: 23,
  fontWeight: 'bold!important',
});

const StyledIconButton = styled(IconButton)({
  position: 'relative',
  bottom: 3,
});

const StyledTypographyText = styled(Typography)({
  color: '#999999',
  fontSize: 16,
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: '#E8600B!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: 200,
});

type AuthForgotPasswordType = {
  onBack: () => void;
};
const StyledBox = styled(Box)({
  cursor: 'pointer',
});
export const ExternalAuthForgotPassword: React.FC<AuthForgotPasswordType> = ({
  onBack,
}) => {
  const query = new URLSearchParams(window.location.search);
  const form = query.has('form') ? query.get('form') : '';
  const { email } = useEvent(registerUserDataEvent);

  const [userForgotPassword, { loading }] =
    useUserForgotPasswordMutationMutation({
      onCompleted: () => onBack(),
    });

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            <img
              src="/LogoVanguardiaMX.svg"
              alt="logo"
              width={225}
              height={25}
            />
            <StyledTypography variant="subtitle1">
              Restaurar contraseña
            </StyledTypography>
          </Box>
        </Grid>
        <Grid item xs={12} mb={2} style={{ textAlign: 'center' }}>
          <StyledEmailTypography variant="subtitle1" pl={1}>
            {email}
            <StyledIconButton onClick={() => onBack()} size="small">
              <EditIcon fontSize="small" />
            </StyledIconButton>
          </StyledEmailTypography>
        </Grid>
        <Grid item xs={8} style={{ textAlign: 'center' }}>
          <StyledTypographyText>
            {`Enviaremos un mensaje al correo ${email} para restablecer
              la contraseña`}
          </StyledTypographyText>
          <Grid item xs={12} mb={3}>
            <StyledBox
              color="#E8600B"
              textAlign="center"
              fontSize={18}
              onClick={() => onBack()}
            >
              {'< Volver'}
            </StyledBox>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              isLoading={Boolean(loading)}
              variant="contained"
              size="large"
              color="secondary"
              disabled={!email}
              onClick={() => {
                userForgotPassword({
                  variables: {
                    data: {
                      email,
                      redirectUrl: query.has('current_url')
                        ? query.get('current_url')
                        : window.location.origin,
                      form,
                    },
                  },
                });
              }}
            >
              Aceptar
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
