import React from 'react';
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Box,
} from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LaunchIcon from '@mui/icons-material/Launch';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

type ActualPosition = {
  num?: number;
};
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    textRed: {
      color: '#E8600B',
    },
    listItem: {
      '& > span': {
        fontWeight: 'bold',
      },
    },
  }),
);

export const Menu: React.FC<ActualPosition> = ({ num = 0 }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box>
      <List component="nav" aria-label="mailbox folders">
        <ListItem>
          <Typography fontWeight="bold">TU CUENTA</Typography>
        </ListItem>
        <Divider />

        <ListItem
          className={num === 1 ? classes.textRed : ''}
          divider
          onClick={() => {
            navigate('/');
          }}
        >
          <ListItemText primary="Mi Perfil" />
          <ArrowForwardIosIcon fontSize="small" />
        </ListItem>
        {/*<ListItem*/}
        {/*  className={num === 2 ? classes.textRed : ''}*/}
        {/*  divider*/}
        {/*  onClick={() => {*/}
        {/*    navigate('/biblioteca');*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <ListItemText primary="Biblioteca" />*/}
        {/*  <ArrowForwardIosIcon fontSize="small" />*/}
        {/*</ListItem>*/}
        <ListItem
          divider
          component="a"
          href="https://vanguardia.com.mx/newsletters"
          target="_blank"
          style={{ color: 'black' }}
        >
          <ListItemText primary="Newsletters" />
          <LaunchIcon fontSize="small" />
        </ListItem>
      </List>
      <List component="nav" aria-label="mailbox folders">
        <ListItem>
          <Typography fontWeight="bold">AYUDA</Typography>
        </ListItem>
        <Divider />
      </List>

      <List component="nav" aria-label="mailbox folders">
        <ListItem
          divider
          component="a"
          href="mailto:suscripciones@vanguardia.com.mx"
          target="_blank"
          style={{ color: 'black' }}
        >
          <ListItemText primary="Escríbenos" />
          <MailOutlineIcon fontSize="small" />
        </ListItem>
        <Divider />
        <a
          style={{ textDecoration: 'none', color: 'black' }}
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=528443808728&text=Hola.+Me+estoy+contactando+desde+el+portal+de+vanguardia.com.mx&type=phone_number&app_absent=0"
        >
          <ListItem divider>
            <ListItemText
              style={{ fontSize: '16px' }}
              primary="Mensaje a Whatsapp"
            />
            <WhatsAppIcon fontSize="small" />
          </ListItem>
        </a>
        <Divider />
        <a
          className={classes.textRed}
          style={{ textDecoration: 'none', fontWeight: 'bold' }}
          href="https://vanguardia.com.mx"
        >
          <ListItem divider>
            <ListItemText
              className={classes.listItem}
              primary="IR A VANGUARDIA"
            />
            <ArrowOutwardIcon fontSize="small" />
          </ListItem>
        </a>
      </List>
    </Box>
  );
};
