import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAuthFacebook } from './auth-facebook-hook';
import {
  useCreateUserMutation,
  useSignUpWithSocialProviderMutation,
  useUserByEmailLazyQuery,
} from '../../shared/types/generated';
import { DOMAIN_SITE } from '../../shared/constans';
import { useCookies } from 'react-cookie';
import moment from 'moment';

export const AuthFacebookCallback: React.FC = () => {
  const url: URL = new URL(window.location.href.replace('#', ''));
  const params: URLSearchParams = url.searchParams;
  const { user, isAuthenticated } = useAuthFacebook();
  const [, setCookie] = useCookies(['_vuser']);

  const [signUpWithSocialProviderMutation] =
    useSignUpWithSocialProviderMutation({
      onCompleted: ({ SignUpWithSocialProviderMutation }) => {
        if (SignUpWithSocialProviderMutation?.access_token) {
          setCookie('_vuser', SignUpWithSocialProviderMutation?.access_token, {
            expires: moment().add(1, 'year').toDate(),
            path: '/',
            domain: DOMAIN_SITE,
          });
          window.location.href = params.has('state')
            ? (params.get('state') as string)
            : '/';
        }
      },
      onError: (data) => {
        console.log('data', data);
      },
    });

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        signUpWithSocialProviderMutation({
          variables: {
            data: {
              email: createUser.email,
              provider: 'FACEBOOK',
            },
          },
        });
      }
    },
    onError: () => {},
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    variables: {
      email: user?.email as string,
    },
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        signUpWithSocialProviderMutation({
          variables: {
            data: {
              email: userByEmail.email,
              provider: 'FACEBOOK',
            },
          },
        });
      } else {
        const urlRegister = params.has('state')
          ? (params.get('state') as string)
          : '/';

        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.name,
              avatar: user?.picture?.data?.url,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'FACEBOOK',
                providerId: user?.email,
                urlRegister: urlRegister,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    console.log('test');
    if (isAuthenticated && user) {
      fetchUser();
    }
  }, [isAuthenticated, user, fetchUser]);

  return (
    <Box
      sx={{ display: 'flex' }}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      flexDirection="column"
    >
      <img alt="loading gif" src={'/loading.gif'} width={500} />
    </Box>
  );
};
