import React, { useRef, useState } from 'react';
import {
  Container,
  Divider,
  Grid,
  Typography,
  Theme,
  Box,
  Avatar,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../auth/auth-hook';
import { UserProfileInfo } from './components/UserProfileInfo';
import { Menu } from './components/Menu';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#E8600B',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
    },
    clearAllButton: {
      background: '#FE0000!important',
      border: 'none!important',
      cursor: 'pointer',
      padding: '0!important',
      width: '22px!important',
      height: '22px!important',
      borderRadius: '100%!important',
      color: 'white',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
  }),
);
type ActiveNum = {
  num?: number;
};

export const Dashboard: React.FC<ActiveNum> = ({ num = 1 }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const refFile = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box>
            <Menu num={num} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <input
            type="file"
            hidden
            ref={refFile}
            onChange={(event) => {
              setFile(
                event.target.files && event.target.files.length
                  ? event.target.files[0]
                  : null,
              );
            }}
          />
          <Box display={'flex'} alignItems={'center'}>
            <Box position={'relative'} mt={'16px'}>
              <Avatar
                onClick={() => refFile.current?.click()}
                style={{ cursor: 'pointer' }}
                sx={{ width: 60, height: 60 }}
                src={
                  file ? URL.createObjectURL(file) : (user?.avatar as string)
                }
              />
            </Box>
            <Box ml={2}>
              <Typography fontWeight="bold" variant="h6" mb={1}>
                Hola y bienvenido{' '}
                <span className={classes.textRed}>
                  {decodeURI(user?.name as string) ||
                    decodeURI(user?.email as string)}
                </span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: '16px' }}>
                Acá encontrarás información detallada de tu cuenta.
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box mb={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="bold"
                variant="h6"
                mb={1}
                textTransform="uppercase"
              >
                Datos de tu cuenta
              </Typography>
            </Box>
            <Box mb={1} mt={1}>
              <Divider />
            </Box>

            <UserProfileInfo file={file} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
