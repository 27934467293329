import jwt_decode from 'jwt-decode';

const query = new URLSearchParams(window.location.search);

export const responseGoogle = (response: { credential: string }): void => {
  return jwt_decode(response.credential);
};

export const getCurrentUrl = (): string | null => {
  return query.has('current_url')
    ? query.get('current_url')
    : window.location.href;
};

export const getFormType = (): string | null => {
  return query.has('form') ? query.get('form') : '';
};

export const getResetPasswordToken = (): string => {
  return query.has('password_reset_token')
    ? (query.get('password_reset_token') as string)
    : '';
};

export const getError = (): string => {
  return query.has('error') ? (query.get('error') as string) : '';
};

export const getFacebookState = (): string => {
  return query.has('state') ? (query.get('state') as string) : '';
};
