import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: '#E8600B!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFC7A4!important',
  },
  width: 200,
});

type AuthForgotPasswordNotFoundType = {
  onBack: () => void;
};

export const AuthForgotPasswordNotFound: React.FC<
  AuthForgotPasswordNotFoundType
> = ({ onBack }) => {
  return (
    <Box width="100%">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            <Box mb={4}>
              <img
                src="/LogoVanguardiaMX.svg"
                alt="logo"
                width={225}
                height={25}
              />{' '}
            </Box>
            <StyledTypography variant="subtitle1">
              Este enlace expiro porfavor solucitar el cambio de contraseña
              nueva mente y volver al campo de correo
            </StyledTypography>
          </Box>
        </Grid>

        <Grid item xs={12} mb={3}>
          <Box textAlign="center">
            <StyledButton
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => onBack()}
            >
              VOLVER
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
