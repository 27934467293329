import React, { useEffect, useState } from 'react';
import { Box, Grid, Input, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import { DropDown1 } from './DropDown1';
import { User, UserSexEnum } from '../../../shared/types/generated';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { updateUserAction } from '../../user/user-actions';
import { openDialogAlert } from '../../alert/alert-actions';

const UserSexList = [
  { label: 'Masculino', value: UserSexEnum.Male },
  { label: 'Femenino', value: UserSexEnum.Female },
  { label: 'No declara', value: UserSexEnum.NoApply },
];
type ProfileRowProps = {
  label?: string;
  value?: string;
  disabled?: boolean;
  name?: string;
  type?: string;
  onChange?: (value: { name: string; value: string }) => void;
};
const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    button: {
      borderColor: theme.palette.primary.main,
    },
    textRed: {
      color: '#FA0000',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      padding: '0 0!important',
    },
    inputStyle: {
      width: '100%!important',
    },
    buttonUnderlinedStyle: {
      background: 'none!important',
      border: 'none!important',
      borderRadius: '0!important',
      cursor: 'pointer',
      padding: '0 0!important',
      borderBottom: '1px solid #949494!important',
      width: '100%!important',
    },
    buttonSave: {
      background: '#E8600B!important',
      color: 'white!important',
    },
    buttonUnconnect: {
      background: '!whiteimportant',
      color: '#E8600B!important',
      border: '1px solid #FA0000',
      width: '180px',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
  }),
);

const StyledButton = styled(ButtonDefault)({
  borderRadius: '99px!important',
  backgroundColor: '#E8600B',
  border: 'none!important',
  color: 'white!important',
  width: 200,
});

const ProfileChangeRow: React.FC<ProfileRowProps> = ({
  label,
  value,
  disabled = false,
  name = '',
  onChange,
  type = 'text',
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={3}>
        <Typography variant="subtitle1" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Input
          name={name}
          type={type}
          className={classes.inputStyle}
          value={value}
          disabled={disabled}
          onChange={(e) =>
            onChange &&
            onChange({
              name: e.target.name,
              value: e.target.value,
            })
          }
        />
      </Grid>
    </Grid>
  );
};

type UserProfileInfoProps = {
  file?: File | null;
};

export const UserProfileInfo: React.FC<UserProfileInfoProps> = ({ file }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState<User>({
    id: 0,
    name: '',
    password: '',
    sex: UserSexEnum.Female,
    email: '',
    birthday: '',
  });
  const { user, refetch } = useAuth();
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  useEffect(() => {
    if (user) {
      setUserData({ ...user });
    }
  }, [user]);

  const [updateUser, loading] = useCallAction(updateUserAction, {
    onCompleted: () => {
      refetch();
      return openDialogAlert('Se ha actualizado tu perfil correctamente.');
    },
    onError: () => {
      return openDialogAlert('Tenemos problemas para actualizar tu perfil.');
    },
  });

  const handleUpdate = (): void => {
    if (userData.password && userData.password !== repeatPassword) {
      return openDialogAlert('Las contraseñas deben coincidir');
    }

    const { id, name, password, sex, birthday } = userData;

    if (password) {
      updateUser({
        data: {
          id,
          name,
          password,
          sex,
          birthday,
          file,
        },
      });
    } else {
      updateUser({
        data: {
          id,
          name,
          sex,
          birthday,
          file,
        },
      });
    }
  };

  return (
    <Box>
      <Box>
        <ProfileChangeRow
          label="Nombre de usuario"
          value={decodeURI(userData.name)}
          name="name"
          onChange={({ name, value }) => {
            setUserData({
              ...userData,
              [name]: value,
            });
          }}
        />
        <ProfileChangeRow
          label="Email"
          value={userData?.email}
          name="email"
          disabled
          onChange={({ name, value }) => {
            setUserData({
              ...userData,
              [name]: value,
            });
          }}
        />
        <ProfileChangeRow
          type="password"
          label="Contraseña"
          value={userData.password}
          name="password"
          onChange={({ name, value }) => {
            setUserData({
              ...userData,
              [name]: value,
            });
          }}
        />
        {userData?.password && (
          <ProfileChangeRow
            label="Repetir Contraseña"
            value={repeatPassword}
            name="password"
            type="password"
            onChange={({ value }) => {
              setRepeatPassword(value);
            }}
          />
        )}
        <Grid container spacing={2} mb={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Sexo
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <DropDown1
              selected={userData?.sex as string}
              items={UserSexList}
              onChange={({ value }) => {
                setUserData({
                  ...userData,
                  sex: value as UserSexEnum,
                });
              }}
            ></DropDown1>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Fecha de nacimiento
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Input
              type="date"
              className={classes.inputStyle}
              value={userData?.birthday}
              onChange={(event) => {
                setUserData({
                  ...userData,
                  birthday: event.target.value,
                });
              }}
            ></Input>
          </Grid>
        </Grid>
      </Box>
      <Box
        my={'30px'}
        alignItems={'center'}
        justifyContent={'center'}
        display={'flex'}
        width={'full'}
      >
        <StyledButton
          isLoading={loading}
          style={{ width: 200, height: 45 }}
          className={classes.buttonConnect}
          onClick={() => handleUpdate()}
        >
          Guardar cambios
        </StyledButton>
      </Box>
    </Box>
  );
};
