import React from 'react';
import { Routes as RoutesComponent, Route } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { client } from './shared/apollo';
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';
import { GOOGLE_API_KEY } from './shared/constans';
import { AuthFacebookCallback } from './modules/auth/AuthFacebookCallback';
import { FacebookLoginView } from './modules/auth/facebook/FacebookLoginView';
import { Session } from './modules/session/Session';
import { Layout } from './shared/components/layout/Layout';
import { Dashboard } from './modules/dashboard/Dashboard';
import { ExternalAuth } from './modules/auth/external/ExternalAuth';
import { AuthProvider } from './modules/auth/AuthProvider';
import { AuthLogout } from './modules/auth/AuthLogout';
// import { Subscriptions } from './modules/subscription/Subscriptions';
// import { Payment } from './modules/payment/Payment';
// import { SubscriptionList } from './modules/subscription/SubscriptionList';

export const Routes: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId={GOOGLE_API_KEY as string}>
        <CookiesProvider>
          <AuthProvider>
            <RoutesComponent>
              <Route path="/external-auth" element={<ExternalAuth />} />
              <Route path="/autenticacion" element={<Auth />} />
              <Route path="/facebook/login" element={<FacebookLoginView />} />
              <Route
                path="/callback/facebook"
                element={<AuthFacebookCallback />}
              />
              <Route
                path="*"
                element={
                  <Session>
                    <RoutesComponent>
                      <Route
                        path="/"
                        element={
                          <Layout>
                            <Dashboard />
                          </Layout>
                        }
                      />
                      {/*<Route*/}
                      {/*  path="/suscripciones"*/}
                      {/*  element={*/}
                      {/*    <Layout>*/}
                      {/*      <Subscriptions />*/}
                      {/*    </Layout>*/}
                      {/*  }*/}
                      {/*/>*/}
                      {/*<Route*/}
                      {/*  path="/biblioteca"*/}
                      {/*  element={*/}
                      {/*    <Layout>*/}
                      {/*      <SubscriptionList />*/}
                      {/*    </Layout>*/}
                      {/*  }*/}
                      {/*/>*/}
                      {/*<Route*/}
                      {/*  path="/metodo-de-pago"*/}
                      {/*  element={*/}
                      {/*    <Layout>*/}
                      {/*      <Payment />*/}
                      {/*    </Layout>*/}
                      {/*  }*/}
                      {/*/>*/}
                      <Route path="/logout" element={<AuthLogout />} />
                    </RoutesComponent>
                  </Session>
                }
              />
            </RoutesComponent>
          </AuthProvider>
        </CookiesProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
};
