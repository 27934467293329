import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchUserGoogle } from '../auth-actions';
import {
  useCreateUserMutation,
  useUserByEmailLazyQuery,
} from '../../../shared/types/generated';
import { GoogleUserType } from '../auth-types';
import { getCurrentUrl } from '../auth-utils';
import { useToasts } from 'react-toast-notifications';
import { createAnalyticEvent } from '../../analytic-event/analytic-event-actions';
import { useCookies } from 'react-cookie';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '99px!important',
  height: 45,
});

const StyledImage = styled('img')({
  marginRight: 10,
});

type GoogleButtonProps = {
  onSuccess?: (email: string) => void;
};

export const GoogleButton: React.FC<GoogleButtonProps> = ({ onSuccess }) => {
  const [user, setUser] = useState<GoogleUserType | undefined | null>(
    undefined,
  );
  const { addToast } = useToasts();
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const [cookies] = useCookies(['analytics_uuid']);

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        callAnalyticEvent({
          name: 'register_user',
          user_id: createUser?.id,
          uuid: cookies.analytics_uuid,
        });
        if (onSuccess && createUser?.email) onSuccess(createUser?.email);
      }
    },
    onError: () => {
      addToast('Ya se encuentra una cuenta relacionada a este correo', {
        appearance: 'error',
      });
    },
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    variables: {
      email: user?.email as string,
      isSocialProvider: true,
      provider: 'GOOGLE',
    },
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        if (onSuccess && user?.email) onSuccess(user?.email);
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.name,
              avatar: user?.picture,
              password: `$%-/${user?.email}+2023,`,
              uuid: cookies.analytics_uuid,
              provider: {
                provider: 'GOOGLE',
                urlRegister: getCurrentUrl(),
                providerId: user?.email,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  const [googleUserCallback] = useCallAction(fetchUserGoogle, {
    onCompleted: (data) => setUser(data),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleUserCallback(tokenResponse);
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    if (user) {
      fetchUser();
    }
  }, [user, fetchUser]);

  return (
    <>
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => {
          googleLogin();
          callAnalyticEvent({
            name: 'gmail_clicked',
            user_id: 0,
            uuid: cookies.analytics_uuid,
          });
        }}
        id="google-event-analytics"
      >
        <StyledImage src="/google-logo.png" width={20} height={20} alt="logo" />
        Continuar con Google
      </StyledButton>
    </>
  );
};
