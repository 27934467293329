import React, { ChangeEvent, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { useChangeUserPasswordMutation } from '../../../../shared/types/generated';
import { registerUserDataEvent } from '../../auth-events';
import { useEvent } from '@cobuildlab/react-simple-state';

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: '#E8600B!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: 200,
});

type AuthResetPasswordFormProps = {
  passwordResetToken?: string;
  onSuccess: (data: { email?: string; password?: string }) => void;
};

export const ExternalAuthResetPasswordForm: React.FC<
  AuthResetPasswordFormProps
> = ({ passwordResetToken, onSuccess }) => {
  const [data, setData] = useState({ password: '', repeatPassword: '' });
  const { email } = useEvent(registerUserDataEvent);

  const [passwordType, setPasswordType] = useState<{
    [key: string]: string | undefined;
  }>({
    repeatPassword: undefined,
    password: undefined,
  });

  const [changeUserPassword, { loading }] = useChangeUserPasswordMutation({
    onCompleted: ({ changeUserPasswordMutation }) => {
      if (changeUserPasswordMutation?.id)
        return onSuccess({
          email: email,
          password: data.password,
        });
    },
  });

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (): void => {
    if (data.password !== data.repeatPassword) {
      console.log('Error password');
      return;
    }

    changeUserPassword({
      variables: {
        data: {
          email,
          password: data.password,
          passwordResetToken,
        },
      },
    });
  };

  const handleVisibility = (field: string): void => {
    if (field && !passwordType[field])
      setPasswordType({ ...passwordType, [field]: 'text' });
    else setPasswordType({ ...passwordType, [field]: undefined });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={4}>
            <StyledTypography variant="subtitle1">
              retablecer contraseña
            </StyledTypography>
          </Box>
          <Box mb={4}>
            <Typography style={{ color: 'red', textAlign: 'justify' }}>
              Para mantener la seguridad de tus datos, te solicitamos cambiar tu
              contraseña cada cierto tiempo.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Contraseña'}
            name="password"
            type={passwordType?.password ?? 'password'}
            value={data?.password}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <StyledVisibilityIcon
                  onClick={() => handleVisibility('password')}
                />
              ),
            }}
            onChange={(event) => onChange(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Repetir contraseña'}
            name="repeatPassword"
            type={passwordType?.repeatPassword ?? 'password'}
            value={data?.repeatPassword}
            fullWidth
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <StyledVisibilityIcon
                  onClick={() => handleVisibility('repeatPassword')}
                />
              ),
            }}
            onChange={(event) => onChange(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              variant="contained"
              size="large"
              color="secondary"
              disabled={!(data.repeatPassword && data.password)}
              onClick={() => onSubmit()}
              isLoading={loading}
            >
              Continuar
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
