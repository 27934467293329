import { createEvent } from '@cobuildlab/react-simple-state';
import { AnalyticsEvenType } from './analytics-event-types';

export const createAnalyticsEvent = createEvent<{
  analyticEvent: AnalyticsEvenType | undefined;
}>({
  initialValue: {
    analyticEvent: undefined,
  },
});
export const createAnalyticsErrorEvent = createEvent();
