import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField, Typography, Box } from '@mui/material';
import { styled } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useCreateUserMutation } from '../../../../shared/types/generated';
import { registerUserDataEvent } from '../../auth-events';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { createAnalyticEvent } from '../../../analytic-event/analytic-event-actions';
import { useCookies } from 'react-cookie';

type AuthRegisterFormProps = {
  onSuccess: (data: RegisterData) => void;
  onBack: () => void;
  onBackHome: () => void;
};

const StyledIcon = styled(EditIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

interface RegisterData {
  [key: string]: string | boolean | number;
}

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: '#E8600B!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  width: 200,
});

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
  color: '#FE0000',
});

export const ExternalAuthRegisterForm: React.FC<AuthRegisterFormProps> = ({
  onSuccess,
  onBack,
  onBackHome,
}) => {
  const query = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const { email } = useEvent(registerUserDataEvent);
  const [registerData, setRegisterData] = useState<RegisterData>({
    email: email ?? '',
    firstName: '',
    password: '',
  });
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const [cookies] = useCookies(['analytics_uuid']);

  const handleSubmit = (): boolean => {
    let checkData = true;
    Object.keys(registerData).forEach((key) => {
      if (!registerData[key] && checkData) checkData = false;
    });
    return checkData;
  };

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
  };

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      setIsLoading(false);

      if (createUser?.id) {
        callAnalyticEvent({
          name: 'register_user',
          user_id: createUser?.id,
          uuid: cookies.analytics_uuid,
        });
        onSuccess(registerData);
      }
    },
    onError: () => {},
  });

  const createUser = (): void => {
    setIsLoading(true);
    createUserMutation({
      variables: {
        data: {
          email: String(registerData?.email),
          name: String(registerData?.firstName),
          password: String(registerData?.password),
          provider: {
            provider: 'SYSTEM',
            urlRegister: query.has('current_url')
              ? query.get('current_url')
              : '',
          },
        },
      },
    });
  };

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Box mb={4}>
          <StyledTypography variant="subtitle1">
            Crea una cuenta
          </StyledTypography>
        </Box>{' '}
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          value={registerData?.email}
          name="email"
          label="Email"
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            endAdornment: <StyledIcon onClick={() => onBack()} />,
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="standard"
          label={'Nombre'}
          name="firstName"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          value={registerData?.firstName}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={'Contraseña'}
          name="password"
          type={passwordType ?? 'password'}
          value={registerData?.password}
          fullWidth
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <StyledVisibilityIcon onClick={() => handleVisibility()} />
            ),
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledBox
          color="#E8600B"
          textAlign="center"
          fontSize={18}
          onClick={() => onBackHome()}
        >
          {'< Volver a todas las opciones'}
        </StyledBox>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center" my={2}>
          <StyledButton
            variant="contained"
            size="large"
            color="secondary"
            disabled={!handleSubmit()}
            onClick={() => createUser()}
            isLoading={isLoading}
          >
            Crear Cuenta
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};
