import { createEvent } from '@cobuildlab/react-simple-state';
import { FacebookUserType, GoogleUserType } from './auth-types';

export const authGoogleEvent = createEvent<GoogleUserType | undefined>({
  initialValue: undefined,
});
export const authGoogleErrorEvent = createEvent();

export const authFacebookEvent = createEvent<FacebookUserType | undefined>({
  initialValue: undefined,
});
export const authFacebookErrorEvent = createEvent();

export const fetchGoogleUserEvent = createEvent();
export const fetchGoogleUserErrorEvent = createEvent();

export interface InitialStateType {
  token: null | string;
}

const INITIAL_STATE = {
  token: null,
};

export const OnTokenEvent = createEvent<InitialStateType>({
  initialValue: INITIAL_STATE,
});
export const OnTokenErrorEvent = createEvent<Error>();

const query = new URLSearchParams(window.location.search);

export const registerUserDataEvent = createEvent<{
  email?: string;
  password?: string;
}>({
  initialValue: {
    email: query.has('email') ? (query.get('email') as string) : '',
    password: '',
  },
});
