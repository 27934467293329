import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  FACEBOOK_APP_ID,
  FACEBOOK_CALLBACK,
  TARGET_ORIGIN_URL,
} from '../../../../shared/constans';
import { FacebookUserType } from '../../auth-types';
import {
  useCreateUserMutation,
  useUserByEmailLazyQuery,
} from '../../../../shared/types/generated';
import MobileDetect from 'mobile-detect';
import { getCurrentUrl } from '../../auth-utils';
import { useToasts } from 'react-toast-notifications';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { createAnalyticEvent } from '../../../analytic-event/analytic-event-actions';
import { useCookies } from 'react-cookie';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '99px!important',
  height: 45,
});

const StyledImage = styled('img')({
  marginRight: 10,
});

type FacebookButtonProps = {
  onSuccess: (email?: string) => void;
};
export const ExternalFacebookButton: React.FC<FacebookButtonProps> = ({
  onSuccess,
}) => {
  const agent = new MobileDetect(window.navigator.userAgent);
  const [user, setUser] = useState<FacebookUserType | undefined | null>(
    undefined,
  );
  const { addToast } = useToasts();
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const [cookies] = useCookies(['analytics_uuid']);

  const currentUrl = getCurrentUrl();
  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    if (!userInfo.email)
      return addToast(
        'No pudimos crear una cuenta usando Facebook porque no hay una dirección de correo electrónico asociada con su cuenta de Facebook.',
      );

    setUser({
      email: userInfo.email,
      name: userInfo.name,
      picture: {
        data: {
          url: userInfo.picture?.data.url,
        },
      },
    });
  };

  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        callAnalyticEvent({
          name: 'register_user',
          user_id: createUser?.id,
          uuid: cookies.analytics_uuid,
        });
        if (onSuccess && createUser?.email) onSuccess(createUser?.email);
      }
    },
    onError: () => {
      addToast('Ya se encuentra una cuenta relacionada a este correo', {
        appearance: 'error',
      });
    },
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    variables: {
      email: user?.email as string,
      isSocialProvider: true,
      provider: 'FACEBOOK',
    },
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        if (onSuccess && user?.email) onSuccess(user?.email);
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.name,
              avatar: user?.picture?.data?.url,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'FACEBOOK',
                urlRegister: getCurrentUrl(),
                providerId: user?.email,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (user) {
      fetchUser();
    }
  }, [user, fetchUser]);

  if (agent.mobile() && agent.phone()) {
    return (
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => {
          callAnalyticEvent({
            name: 'facebook_clicked',
            user_id: 0,
            uuid: cookies.analytics_uuid,
          });

          window.parent.postMessage(
            {
              facebookUrl: `${FACEBOOK_CALLBACK}/facebook/login?state=${currentUrl}`,
            },
            TARGET_ORIGIN_URL,
          );
        }}
      >
        <StyledImage
          src="/facebook-icon.png"
          width={18}
          height={18}
          alt="logo"
        />
        Continuar con Facebook
      </StyledButton>
    );
  }

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID as string}
      callback={responseFacebook}
      fields="name,email,picture"
      isMobile={false}
      autoLoad={false}
      scope="public_profile,email"
      render={(renderProps) => {
        return (
          <StyledButton
            type="button"
            className=""
            variant="outlined"
            fullWidth
            onClick={() => {
              renderProps.onClick();
              callAnalyticEvent({
                name: 'facebook_clicked',
                user_id: 0,
                uuid: cookies.analytics_uuid,
              });
            }}
          >
            <StyledImage
              src="/facebook-icon.png"
              width={18}
              height={18}
              alt="logo"
            />
            Continuar con Facebook
          </StyledButton>
        );
      }}
    />
  );
};
