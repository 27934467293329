import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FACEBOOK_APP_ID, FACEBOOK_CALLBACK } from '../../../shared/constans';
import { Box } from '@mui/material';
import { ReactFacebookLoginInfo } from 'react-facebook-login';

export const FacebookLoginView: React.FC = () => {
  const query = new URLSearchParams(window.location.search);

  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    console.log('userInfo', userInfo);
  };

  return (
    <>
      <FacebookLogin
        appId={FACEBOOK_APP_ID as string}
        callback={responseFacebook}
        fields="name,email,picture"
        redirectUri={`${FACEBOOK_CALLBACK}/callback/facebook`}
        responseType="token"
        state={query.has('state') ? (query.get('state') as string) : ''}
        isMobile
        render={(renderProps) => {
          if (renderProps.isSdkLoaded) {
            setTimeout(() => {
              renderProps.onClick();
            }, 3000);
          }

          return (
            <Box
              sx={{ display: 'flex' }}
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              flexDirection="column"
            >
              <Box mb={4}>
                <img alt="loading gif" src={'/loading.gif'} width={250} />
              </Box>
            </Box>
          );
        }}
      />
    </>
  );
};
