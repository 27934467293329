import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import AppleLogin from 'react-apple-login';
import { AppleUserType } from '../auth-types';
import jwt_decode from 'jwt-decode';
import {
  useCreateUserMutation,
  useUserByEmailLazyQuery,
} from '../../../shared/types/generated';
import { getCurrentUrl } from '../auth-utils';
import { useToasts } from 'react-toast-notifications';
import { createAnalyticEvent } from '../../analytic-event/analytic-event-actions';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useCookies } from 'react-cookie';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '99px!important',
  height: 45,
  paddingRight: '40px!important',
});

const StyledImage = styled('img')({
  marginRight: 10,
  position: 'relative',
  right: 3,
});

type AppleButtonProps = {
  onSuccess: (email: string) => void;
};
export const AppleButton: React.FC<AppleButtonProps> = ({ onSuccess }) => {
  const [user, setUser] = useState<AppleUserType | undefined | null>(undefined);
  const { addToast } = useToasts();
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const [cookies] = useCookies(['analytics_uuid']);
  const [createUserMutation] = useCreateUserMutation({
    onCompleted: ({ createUser }) => {
      if (createUser?.id) {
        callAnalyticEvent({
          name: 'register_user',
          user_id: createUser?.id,
          uuid: cookies.analytics_uuid,
        });
        if (onSuccess && createUser?.email) onSuccess(createUser?.email);
      }
    },
    onError: () => {
      addToast('Ya se encuentra una cuenta relacionada a este correo', {
        appearance: 'error',
      });
    },
  });

  const [fetchUser] = useUserByEmailLazyQuery({
    variables: {
      email: user?.email as string,
      isSocialProvider: true,
      provider: 'APPLE',
    },
    onCompleted: ({ userByEmail }) => {
      if (userByEmail?.id) {
        if (onSuccess && user?.email) onSuccess(user?.email);
      } else {
        createUserMutation({
          variables: {
            data: {
              email: String(user?.email),
              name: user?.email,
              password: `$%-/${user?.email}+2023,`,
              provider: {
                provider: 'APPLE',
                urlRegister: getCurrentUrl(),
                providerId: user?.email,
              },
            },
          },
        });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    if (user) {
      fetchUser();
    }
  }, [user, fetchUser]);
  return (
    <>
      <AppleLogin
        clientId="mx.vang.suscriptores.client"
        redirectURI="https://miperfil.vanguardia.com.mx/apple/callback"
        callback={(data: {
          authorization: { code?: string; id_token?: string };
        }) => {
          console.log('data', data);
          if (data?.authorization && data?.authorization?.id_token) {
            const tokenDecoded: { email?: string } = jwt_decode(
              data?.authorization?.id_token,
            );
            setUser({
              email: tokenDecoded.email,
            });
          }
        }} // Catch the response
        scope="name email"
        usePopup
        responseMode="query"
        render={(
          renderProps, //Custom Apple Sign in Button
        ) => (
          <StyledButton
            type="button"
            className=""
            variant="outlined"
            fullWidth
            onClick={() => {
              renderProps.onClick();
              callAnalyticEvent({
                name: 'apple_clicked',
                user_id: 0,
                uuid: cookies.analytics_uuid,
              });
            }}
            id="apple-event-analytics"
          >
            <StyledImage src="/apple.webp" width={20} height={20} alt="logo" />{' '}
            Continuar con Apple
          </StyledButton>
        )}
      />
    </>
  );
};
