import React from 'react';
import { Box } from '@mui/material';

export const MainLoader: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <img src="/loading.gif" alt="loader-gif" width={200} height={200} />
    </Box>
  );
};
