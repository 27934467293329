import React, { useEffect, useState } from 'react';
import { Provider } from './AuthContext';
import {
  Subscription,
  useFetchUserLazyQuery,
  User,
} from '../../shared/types/generated';
import { ApolloError } from '@apollo/client/errors';
import { useCookies } from 'react-cookie';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchToken } from './auth-actions';
import moment from 'moment';
import { DOMAIN_SITE, ANALYTICS_ENDPOINT } from '../../shared/constans';

type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    '_vuser',
    'loginWithSocial',
    'analytics_uuid',
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  const [socialProvider, setSocialProvider] = useState<string | undefined>(
    undefined,
  );
  const [subscription, setSubscription] = useState<
    Subscription | undefined | null
  >(undefined);
  const [error, setError] = useState<ApolloError | Error | undefined>(
    undefined,
  );
  const analyticsAuthentication = async (): Promise<
    { uuid: string } | undefined
  > => {
    const response = await fetch(`${ANALYTICS_ENDPOINT}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'analytics-session-origin': window.location.href,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return undefined;
  };

  useEffect(() => {
    if (!cookies.analytics_uuid) {
      analyticsAuthentication().then((result) => {
        if (result) {
          setCookie('analytics_uuid', result.uuid, {
            expires: moment().add(1, 'day').toDate(),
            path: '/',
            domain: DOMAIN_SITE,
          });
        }
      });
    }
  }, [cookies.analytics_uuid, setCookie]);

  const [fetchUser] = useFetchUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false);

      if (data.user) {
        setSubscription(
          data.user?.subscriptions?.find((_subscription) => {
            const startDate = moment(_subscription?.startDate).format(
              'YYYY/MM/DD',
            );
            const endDate = moment(_subscription?.endDate).format('YYYY/MM/DD');
            const compareDate = moment(new Date(), 'YYYY/MM/DD');
            return (
              _subscription?.status === 'active' ||
              compareDate.isBetween(startDate, endDate)
            );
          }),
        );
        setUser(data.user as User);
        setIsAuthenticated(true);
        return;
      } else {
        removeCookie('_vuser');
      }
    },
    onError: (data) => {
      setError(data);
    },
  });

  const [callAction] = useCallAction(fetchToken, {
    onCompleted: ({ token }) => token && fetchUser(),
    onError: (data) => {
      setError(data);
    },
  });
  useEffect(() => {
    callAction(cookies._vuser);
  }, [callAction, cookies._vuser]);

  useEffect(() => {
    if (cookies.loginWithSocial) {
      setSocialProvider(cookies.loginWithSocial);
    }
  }, [cookies.loginWithSocial]);

  const refetch = (): void => {
    fetchUser();
  };

  const handleSocialProvider = (userSocialProvider: string): void => {
    setSocialProvider(userSocialProvider);
  };

  const values = {
    isAuthenticated,
    isLoading,
    user,
    error,
    refetch,
    socialProvider,
    handleSocialProvider,
    subscription,
  };

  return <Provider value={values}>{children}</Provider>;
};
